import { useContext } from "react"
import { AuthContext } from '../../contexts/context/auth';
import Container from '../../components/container';


export default function ProfilePage() {
    
    const {user} = useContext(AuthContext)

    return(
        <Container className="z-10 relative flex flex-col items-center">
            <h1 className="text-white text-2xl sm:text-4xl lg:text-6xl -translate-y-20 lg:-translate-y-28 text-center">
                Details du compte
            </h1>

            <div className="">
                <div className="flex gap-8 ">
                    <span className="pb-1 w-28">Nom complet</span>
                    <span>{ `${user?.firstname} ${user?.lastname}` } </span>
                </div>
                <div className="flex gap-8 mt-8">
                    <span className="pb-1 w-28">Email</span>
                    <span>{ user?.email } </span>
                </div>
                <div className="flex gap-8 mt-8">
                    <span className="pb-1 w-28">Téléphone</span>
                    <span>{ user?.phone } </span>
                </div>
                
            </div>

        </Container>
    )
};
