export function convertFileToB64(file : File) : FileReader{

    const fileReader = new FileReader()    
    fileReader.readAsDataURL(file)

    return fileReader
}

export function formatNumber(value = 0, currency = "USD") : string {
    return value.toLocaleString(undefined, { maximumFractionDigits : 2, currency }) + "$"
}