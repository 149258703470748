import CustomSelectBox from "./custom-select-box"

type props = {
    onChange? : (newValue : any) => void
    id? : string
    value? : string
    label : string
    icon? : string
    placeholder? : string
    options? : {label : string, value? : string}[]
    hasError? : boolean
}

export default function SelectBoxWithLIconsLabel( {hasError, value , id, label, icon, placeholder, options, onChange,} : props){

    return(
        <div className='flex items-center gap-4 h-fit w-full  '>
            <div className="flex items-center justify-center">
                <i className={`text-4xl text-primary ${icon}`} />
            </div>
            <div className="flex flex-col w-full">
                <label htmlFor={id} className="font-bold">{label} <span className="text-primary">*</span> { hasError && <span className="text-xs text-primary">champ recquis</span>}</label>
                <CustomSelectBox 
                    id={id}
                    className="rounded w-full h-10" 
                    placeholder=""
                    selected={ value }
                    options={ options }
                    onChange={ option => (option.value !== value) && onChange && onChange(option.value) }
                />
            </div>
        </div>
    )
}