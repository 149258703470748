import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Logo, MyForm, Section } from "../components";
import { FieldInputType, InputFieldTemplate2Small } from "../components/my-form";
import InputPatterns from "../utils/constants/input-patterns";
import { useAuth } from "../hooks";
import HttpStatusCode from "../utils/constants/http-status-code";
import SignupRequestDto from '../models/singup.dto';


export default function Signup(){

    const [isSbumiting, setIsSubmiting] = useState(false)
    const [serverErrorCode, setServerErrorCode] = useState<number>()
    const ErrorArea = useCallback( () => {
        if(!serverErrorCode){
            return <></>
        }

        if(serverErrorCode === HttpStatusCode.UNAUTHORIZED){
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm"> Informations de connexion non valide </span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }else if( serverErrorCode === HttpStatusCode.FORBIDEN ){
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-yellow-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-yellow-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm">Veuillez valider/activer votre compte</span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }else{
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm">Oops! un petit soucis, veuillez réessayer svp!</span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }
    } , [serverErrorCode])

    const {signup} = useAuth()

    const navigate = useNavigate()

    const onSubmit = useCallback( (data : SignupRequestDto) => {
        setIsSubmiting(true)
        setServerErrorCode(undefined)
        signup( data )
        .then( () =>  navigate('/auth', { state : { signupSucceed : true, username : data.email } }) )
        .catch( err => setServerErrorCode(err?.response?.status || 500) )
        .finally( () => setIsSubmiting(false) )
    }, [signup, navigate])

    useEffect( () => {
        document.title = 'SA-HD | Inscription'
    }, [])
    
    return (
        <Section className="min-h-screen flex" container={false}>
            <div className="min-h-screen hidden md:flex md:flex-col items-end pt-48 pr-8 w-[40%] bg-secondary bg-[url(/src/assets/images/painting-splash.png)] bg-cover bg-no-repeat bg-right">
                <Logo className="w-60"/>
            </div>
            <div className="min-h-full w-full md:w-[60%] bg-secondary bg-opacity-90 flex flex-col justify-center p-4 sm:p-8 2xl:p-20">
                <img src="/images/logo.webp" className="md:hidden object-contain w-52 block mx-auto my-12" alt="" />
                <h1 className="text-2xl sm:text-3xl lg:text-5xl text-white font-semibold">Créez un compte</h1>
                <ErrorArea />
                <MyForm

                    className="mt-4 flex-1 flex flex-wrap justify-between overflow-y-auto"
                    fields={ {
                        
                        id : {
                            placeholder : 'code client',
                            label : 'Code',
                            id : 'customer-number',
                            required : true,
                            onEmptyErrorMessage : 'champ obligatoire',
                        },
                        companyName:{
                            id : "company-name",
                            label : "Entreprise",
                            placeholder : "nom de l'entreprise",
                            required : true,
                            onEmptyErrorMessage : 'champ obligatoire',
                            // renderFieldComponent : props => <div className="w-[48%]"></div>
                        },
                        firstname : {
                            placeholder : 'prénom',
                            label : 'Prénom',
                            id : 'first',
                            required : true,
                            onEmptyErrorMessage : 'champ obligatoire',
                        },
                        lastname : {
                            placeholder : 'nom',
                            label : 'Nom de famille',
                            id : 'last',
                            required : true,
                            onEmptyErrorMessage : 'champ obligatoire',
                        },
                        phone : {
                            placeholder : 'numéro de téléphone',
                            label : 'Téléphone',
                            id : 'phone',
                            required : true,
                            onEmptyErrorMessage : 'champ obligatoire',
                            extraData : { fullWidth : true}
                        },
                        address : {
                            placeholder : 'adresse',
                            label : 'Adresse',
                            id : 'address',
                            required : true,
                            onEmptyErrorMessage : 'champ obligatoire',
                            extraData : { fullWidth : true}
                        },
                        city : {
                            placeholder : 'ville',
                            label : 'Ville',
                            id : 'city',
                            required : true,
                            onEmptyErrorMessage : 'champ obligatoire',
                        },
                        postalCode : {
                            placeholder : 'code postal',
                            label : 'Code postal',
                            id : 'code-portal',
                            required : true,
                            onEmptyErrorMessage : 'champ obligatoire',
                        },
                        email : {
                            placeholder : 'adresse mail',
                            label : 'Email',
                            id : 'email',
                            required : true,
                            pattern : InputPatterns.EMAIL,
                            onEmptyErrorMessage : 'champ obligatoire',
                            onInvalidErrorMessage : 'adresse email invalid!'
                        },
                        password : {
                            placeholder : 'mot de passe',
                            label : 'Mot de passe',
                            id : 'password',
                            required : true,
                            type : FieldInputType.PASSWORD,
                            pattern : InputPatterns.PASSWORD,
                            onEmptyErrorMessage : 'champ obligatoire',
                            onInvalidErrorMessage : 'le mot de passe doit compter aumoins 8 characters et contenir les lettres (minuscule et majuscule) et  aumoins un character special'
                        },
                    }}
                    renderFieldComponent={InputFieldTemplate2Small}
                    onSubmit={ fields => onSubmit(fields as any)}
                >

                    <Button className='mt-3 text-xl py-3 w-full flex items-center justify-center gap-2 rounded-md shadow-md' disabled={isSbumiting} >
                        <span>créez le compte</span>
                        { isSbumiting ? <span className='fa-solid fa-circle-notch animate-spin' /> : <i className="fas fa-unlock" />  }
                    </Button>

                    <Link to='/auth/' className="flex w-fit mx-auto my-4 text-slate-100 underline underline-offset-2 hover:text-slate-400">Connexion</Link>
                </MyForm>
            </div>
        </Section>
    )
}