import { useCallback, useMemo, useRef } from 'react';
import { Product } from "../models"
import { OrderItem } from "../models/order"
import { formatNumber } from "../utils/helpers/others"
import CustomSelectBox from "./custom-select-box"
import { useApiSecured, useAuth } from '../hooks';

const TAXE_ENV = 14.59

type props = {
    removeItem? : (orderItem : OrderItem) => void
    addItem? : () => void
    onChange? : (oldItem : OrderItem | undefined, newItem : OrderItem) => void
    item? : OrderItem
    productList? : Product[],
    onSearchProduct? : (searchText : string) => void
    onEndProductsListReached? : () => void
    isLoadingData? : boolean
}


export default function OrderItemComponent({item, productList = [],isLoadingData, onSearchProduct,onEndProductsListReached, removeItem, addItem, onChange} : props) {

    const { customerNumber } = useAuth().user || {}
    const { get : getProdoctDetails } = useApiSecured("products/details").api
    const productDetailsRef = useRef<HTMLDivElement | null>(null)
    const loader = useRef<HTMLDivElement | null>(null)
    const errorDivRef = useRef<HTMLDivElement | null>(null)
    const itemId = useRef(item?._id)
    const products = useMemo( () => {
        if( item && !productList.find( product => product._id === item._id)){
            return [item, ...productList]
        }else{
            return productList
        }
    }, [productList, item])

    const isLoading = useMemo( () => isLoadingData, [isLoadingData])
    const getProductPrice = useCallback( async (prodId : string, qte : number) => {
        errorDivRef?.current?.classList.replace("flex","hidden")
        if(prodId === item?._id){
            return item?.price || ( 
                await getProdoctDetails(`?product=${prodId}&qte=${qte}&customer=${customerNumber}`)
                .then( ({data}) => data.CustomerPrice)
                .catch( () => {
                    itemId.current = prodId
                } )
            )            
        }else{
            productDetailsRef?.current?.classList.replace("flex","hidden")
            loader?.current?.classList.toggle("hidden")
            return await getProdoctDetails(`?product=${prodId}&qte=${qte}&customer=${customerNumber}`)
            .then( ({data}) =>{
                productDetailsRef?.current?.classList.replace("hidden","flex")
                errorDivRef?.current?.classList.replace("flex","hidden")
                return  data.CustomerPrice
            })
            .catch( () => {
                errorDivRef?.current?.classList.replace("hidden","flex")
                itemId.current = prodId
            })
            .finally( () => {
                loader?.current?.classList.toggle("hidden")
            })
        }
    }, [item, customerNumber, getProdoctDetails])

    const handleOnChange = useCallback( async ({prodId = item?._id, qte = item?.qte || 1} : {prodId? : string, qte? : number}) => {
        if((prodId === item?._id) && (qte === item?.qte)){
            return
        }
        
        const product = productList.find( prod => prod._id === prodId)        
        const price = await getProductPrice(prodId!, qte)
        if(price){
            const subTotalHT =  (price || 0) * qte
            const taxeEnv =  subTotalHT * TAXE_ENV / 100
            const subTotalTTC =  subTotalHT + taxeEnv
            const newItem = {...product, price, qte , subTotalHT, subTotalTTC, taxeEnv}            
            onChange && onChange(item, newItem)            
        }
    }, [item, productList, getProductPrice, onChange])


    return (
        <div className="group relative flex items-center gap-2 md:gap-4 lg:gap-8 py-4 px-2 odd:bg-gray-50 border-b">
            <div className="w-36 sm:w-56">
                <CustomSelectBox
                    onSearch={onSearchProduct}
                    isLoadingData={isLoading}
                    onEndListReached={onEndProductsListReached}
                    search
                    onChange={ ({value}) => handleOnChange({prodId : value}) }
                    placeholder="Séléctionnez un produit" 
                    className="w-full h-10 rounded"
                    selected={item?._id}
                    sort
                    options={products.map( prod => ({label : prod.title!, value : prod._id}))} 
                />
            </div>
            <div ref={loader} className="hidden flex-1 h-10 ml-1 bg-gray-400 animate-pulse" />
            <div ref={errorDivRef} className="hidden flex-1 h-10 ml-1 items-center justify-center text-primary">
                opps! nous n'avons pas pu procéder. <button onClick={ () => handleOnChange({prodId : itemId.current})} className="ml-1 p-1 underline hover:text-black">cliquez pour réessayer</button>
            </div>
            {

            }
            {
                item?._id && (
                    <div ref={productDetailsRef} className="flex-1 flex items-center gap-2 md:gap-4 lg:gap-8">
                        <div className="hidden xl:block w-80 pl-2">
                            <p className="truncate whitespace-pre-line max-h-8 leading-4">{item?.description}</p>
                        </div>
                        <div className="w-10 sm:w-16 h-10 sm:pl-2">
                            <input
                                onChange={ e => handleOnChange({qte : Number.parseInt(e.target.value)}) }
                                type="number" 
                                min={1} 
                                max={50} 
                                defaultValue={item?.qte || 1} 
                                className="w-full h-full p-1 border rounded" 
                            />
                        </div>
                        <div className="hidden pl-2 w-24">
                            <span> Oui </span>
                        </div>
                        <div className="hidden pl-2 w-12">
                            <span> ### </span>
                        </div>
                        <div className="w-16 2xl:w-28 sm:pl-2">
                            <span className="hidden sm:block">{ formatNumber(item?.price) }</span>
                            <span className="sm:hidden">{ formatNumber(item?.price! + item.taxeEnv!) }</span>
                        </div>
                        <div className="sm:pl-2 w-24 hidden sm:block">
                            <span>{ formatNumber(item?.taxeEnv) }</span>
                        </div>
                        <div className="sm:pl-2 min-w-[4rem]">
                            <span>{ formatNumber(item?.subTotalTTC) }</span>
                        </div>
                        

                        <button onClick={ () => addItem && addItem()} className="fas fa-add hidden group-last:flex absolute z-10 sm:-left-3 -bottom-3 w-6 h-6 justify-center items-center animate-pulse hover:animate-none bg-slate-700 text-white text-xs rounded-full " />
                        <button onClick={ e => removeItem && removeItem(item)} className="fas fa-close hidden group-hover:flex cursor-pointer absolute top-10 sm:top-6 right-0 sm:-right-3 w-5 h-5 hover:scale-125 items-center justify-center text-xs hover:bg-primary hover:text-white text-primary bg-red-200 rounded-full" />
                        
                    </div>
                )
            }
        </div>
    )
    
};
