import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Logo, MyForm, Section } from "../components";
import { FieldInputType, InputFieldTemplate2Big } from "../components/my-form";
import InputPatterns from "../utils/constants/input-patterns";
import SigninRequestDto from '../models/user/signin-request.dto';
import { useAuth } from "../hooks";
import HttpStatusCode from "../utils/constants/http-status-code";
import { AuthContext } from '../contexts/context/auth';


export default function Signin(){

    const navigate = useNavigate()
    const {signin} = useAuth()
    const {sessionHasExpired, setSessionExpired} = useContext(AuthContext)
    const location = useLocation()
    const locationState = location.state as any 

    const showUpdateSucceed = useMemo( () =>  {

        const updateStatus = (location.state as any)?.updateSucceed
        if(updateStatus){
            setSessionExpired(false)
        }
        return updateStatus
    }, [location.state, setSessionExpired] )
    const [isSbumiting, setIsSubmiting] = useState(false)
    const [serverErrorCode, setServerErrorCode] = useState<number>()
    const ErrorArea = useCallback( () => {
        if(!serverErrorCode){
            return <></>
        }

        if(serverErrorCode === HttpStatusCode.UNAUTHORIZED){
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm"> Informations de connexion non valide </span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }else if( serverErrorCode === HttpStatusCode.LOCKED ){
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-yellow-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-yellow-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm">Veuillez valider/activer votre compte</span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-yellow-700 text-white hover:bg-yellow-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }else{
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm">impossible de se connecter, vérifier votre connexion internet</span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }
    } , [serverErrorCode])


    const onSubmit = useCallback( (signinRequestDto : SigninRequestDto) => {
        setIsSubmiting(true)
        setServerErrorCode(undefined)
        signin( signinRequestDto )
        .then( () =>  navigate('/') )
        .catch( err => setServerErrorCode(err?.response?.status || 500) )
        .finally( () => {
            setIsSubmiting(false)
        })
    }, [signin, navigate])

    useEffect( () => {
        document.title = 'SA-HD | Connexion'
    }, [])
    
    return (
        <Section
            className="h-screen flex"
            container={false}
        >
            {/* E:\new-work-projects\service-auto-hd\web\src\./assets/images/painting-splash.png */}
            <div className="h-full hidden md:flex md:flex-col items-end pt-48 pr-8 w-[40%] bg-secondary bg-[url(/src/assets/images/painting-splash.png)] bg-cover bg-no-repeat bg-right">
                <Logo className="w-60"/>
                {/* <img src="/images/red-car.png" alt="" /> */}
            </div>
            <div className="min-h-full w-full md:w-[60%] bg-secondary bg-opacity-90 flex flex-col justify-center px-4 sm:px-8 2xl:px-20">
                <img src="/images/logo.webp" className="md:hidden object-contain w-52 block mx-auto my-12" alt="" />
                <h1 className="text-2xl sm:text-3xl lg:text-5xl text-white font-semibold">Connectez-vous</h1>
                { sessionHasExpired && (
                    <div className="relative w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                        <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                        <span className="text-white text-sm"> la session a expiré! veuillez vous connecter à nouveau! </span>
                        <i onClick={ () => setSessionExpired(false)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                    </div>
                )}

                { locationState?.signupSucceed && (
                    <div className=' my-2 w-fit flex gap-2 px-4 text-white text-center bg-green-600 py-2 rounded relative' >
                        <div onClick={ () => navigate("") } className='absolute -top-3 -right-2 flex items-center justify-center w-6 h-6 shadow bg-green-700 hover:bg-green-800 cursor-pointer rounded-full text-white fas fa-close'  />
                        <i className="fa-solid fa-triangle-exclamation text-4xl text-white" />
        
                        <p className='text-sm leading-5'>
                            Félicitation! Inscription réussie. vérifier votre boite-mail <br /> 
                            <span className='font-semibold'>{locationState?.username}</span> 
                            <br/> et cliquer sur le lien d'activation pour activer votre compte!
                        </p>
                    </div>
                )}
                { showUpdateSucceed && (
                    <div className="relative w-fit flex items-center mt-5 p-4 gap-4 bg-green-600 rounded text-white">
                        <i className="fas fa-bug bg-white text-green-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                        <span className="text-white text-sm"> Mis-à-jour du mot de passe réussit </span>
                        <i onClick={ () => navigate("")} className="fas fa-close text-xs cursor-pointer bg-green-700 text-white hover:bg-green-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                    </div>
                )}
                <ErrorArea />
                <MyForm

                    className="w-full md:w-full"
                    fields={ {
                        username : {
                            placeholder : 'mail@icahsbs.com',
                            label : 'Email',
                            id : 'email',
                            required : true,
                            pattern : InputPatterns.EMAIL,
                            onEmptyErrorMessage : 'champ obligatoire',
                            onInvalidErrorMessage : 'adresse email invalid!'
                        },
                        password : {
                            placeholder : 'mot de passe',
                            label : 'Mot de passe',
                            id : 'password',
                            required : true,
                            type : FieldInputType.PASSWORD,
                            pattern : InputPatterns.PASSWORD,
                            onEmptyErrorMessage : 'champ obligatoire',
                            onInvalidErrorMessage : 'le mot de passe doit compter au moins 8 characters et contenir les lettres (minuscule et majuscule) et  au moins un character special'
                        }
                    }}
                    renderFieldComponent={InputFieldTemplate2Big}
                    onSubmit={ fields => onSubmit(fields as any)}
                >

                    <Button 
                        className='text-xl py-3 w-full flex items-center justify-center gap-2 rounded-md shadow-md' 
                        disabled={isSbumiting}
                    >
                        <span>connexion</span>
                        { isSbumiting ? <span className='fa-solid fa-circle-notch animate-spin' /> : <i className="fas fa-unlock" />  }
                    </Button>

                    <Link to='/auth/signup' className="flex w-fit mx-auto my-4 text-slate-100 underline underline-offset-2 hover:text-slate-400">Créez un compte</Link>
                    <Link to='/auth/reset-password' className="flex w-fit mx-auto my-4 text-slate-100 underline underline-offset-2 hover:text-slate-400">Mot de passe oublié?</Link>
                </MyForm>
            </div>
        </Section>
    )
}