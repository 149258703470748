import { Link } from "react-router-dom"

type props = {
    className? : string
}
export default function Logo({className} : props) {
    
    return(
        <Link to='/' className={`flex w-fit gap-1 justify-start items-center font-secondary ${className} text-2xl hover:scale-110`}>
            {/* <span className=" text-indigo-700">Auto</span>
            <span className=" text-indigo-400">.HD</span> */}
            <img src="/images/logo.webp" alt="duma-reader-logo" className={`w-28 ${className}`} />
        </Link>
    )
};
