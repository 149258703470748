import moment from 'moment' 

export const getCurrentDate = () => new Date( Date.now() );
export const getCurrentTime = () => getCurrentDate().getTime()

export const timeLapsedFromNow = (date : Date) => moment(date).fromNow()
export const isDateBetween = (date : Date, period : { start : Date, end : Date} ) => {
    if(!date){
        return false
    }    
    date = new Date(date)
    const isAfterStart = date.getDate() >= new Date(period.start).getDate()
    const isBeforeEnd = date.getDate() <= new Date(period.end).getDate()
    return isAfterStart && isBeforeEnd
}
export const getDate = (date? : Date) => !date ? undefined : moment(date).toDate()
export const formatDate = (date? : Date) => !date ? undefined : moment(date).format('YYYY-MM-DD');