import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Footer, Header } from "../../components";
import { AuthContext } from "../../contexts/context/auth";

export default function PrivatePageWrapper() {
    
    const navigate = useNavigate()
    const {user} = useContext( AuthContext )

    useEffect( () => {

        !user && navigate('/auth')
        
    }, [user, navigate])
    
    return !user ? <></> : (
        <>
            <Header />
            <div className="min-h-screen">
                <div className="h-40 lg:h-60 bg-secondary bg-[url(/src/assets/images/hdtexture.png)] bg-cover bg-no-repeat relative flex justify-end overflow-hidden">
                    <p className="h-[220%] w-fit  translate-y-16 lg:-translate-y-10 scale-110 rotate-90">
                        <img src="/images/cercle.png" alt="" className="h-full"  />
                    </p>
                </div>
                <Outlet />
            </div>
            {/* <Footer /> */}
        </>
    )
};
