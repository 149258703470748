import Container from "./container"

type props = {
    className? : string
    container? : boolean
    containerClassName? : string
    containerStyle? : any
    style? : any
    children? : any
    id? : string
}
export default function Section( {className = '', id, children, container = true, containerStyle = {}, containerClassName = ''} : props ){
    return (
        <section className={`bg-cover ${className}`} id={id}>
            {
                container ? (
                    <Container className={`${containerClassName} `} style={containerStyle}>
                        {children}
                    </Container>
                ) : children
            }
        </section>
    )
}