import { useState } from "react"

type props = {
    onChange? : (note : string, value : string) => void
}


export default function OrderNoteComponent({ onChange = () => {}} : props) {
    const [selectedTab, setSelectedTab] = useState(1)

    return(
        <>
            <div className="w-full flex flex-col">
                {/* tabs-header */}
                <div className="flex overflow-hidden ">
                    <div 
                        onClick={ () => setSelectedTab(1)}
                        className={`${selectedTab === 1 ? 'bg-red-600 hover:bg-red-800 text-white font-semibold' : 'bg-gray-100 hover:bg-gray-300'} py-1 px-2 rounded-tl-lg cursor-pointer`}
                        >
                        Mes notes
                    </div>
                    <div 
                        onClick={ () => setSelectedTab(2)}
                        className={`${selectedTab === 2 ? 'bg-red-600 hover:bg-red-800 text-white font-semibold' : 'bg-gray-100 hover:bg-gray-300'} py-1 px-2 rounded-tr-lg cursor-pointer`}
                        >
                        Notes à Auto-HD
                    </div>
                </div>
                <textarea 
                    className={`flex w-full py-1 px-2 border rounded-b ${selectedTab === 1 ? "" : "hidden" }`} 
                    rows={4}
                    name="transitNote"
                    onChange={ ({target}) => onChange( target.name, target.value)  } 
                />
                <textarea 
                    className={`flex w-full py-1 px-2 border rounded-b ${selectedTab === 2 ? "" : "hidden" }`} 
                    rows={4}
                    name="ownerNote"
                    onChange={ ({target}) => onChange( target.name, target.value)  } 
                />
            </div>
        </>
    )
};

