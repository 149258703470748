import { ReactNode, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import GlobalContextProvider from "./contexts/context/global";
import { ResetPassword, Signin, UpdatePassword, NewOrderPage, OrderDetailsPage, OrdersHistoryPage, PrivatePageWrapper, ProfilePage, Signup } from "./pages";

import './utils/themes/theme.scss'


function ScrollToTop({children} : {children : ReactNode}){
  const location = useLocation()

  useEffect(() => window.scrollTo(0, 0) , [location])


  return(
    <>
      {children}
    </>
  )
}

function App() {
  
  return (
    <GlobalContextProvider>
      <BrowserRouter>
        <ScrollToTop>
          
          <Routes>
            <Route path="auth" element={<Signin />} />
            <Route path="auth/signup" element={<Signup />} />
            <Route path="auth/reset-password" element={<ResetPassword />} />
            <Route path="auth/update-password" element={<UpdatePassword />} />

            <Route path="/" element={<PrivatePageWrapper />}>
              <Route path="" element={<ProfilePage />} />
              <Route path="history" element={<OrdersHistoryPage />} />
              <Route path="new-order" element={<NewOrderPage />} />
              <Route path="order-details" element={<OrderDetailsPage />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </GlobalContextProvider>
  );
}

export default App;
