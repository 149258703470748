import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Logo, MyForm, Section } from "../components";
import { InputFieldTemplate2Big, FieldInputType } from '../components/my-form';
import { useAuth } from "../hooks";
import { UpdatePasswordDto } from "../models";
import HttpStatusCode from "../utils/constants/http-status-code";
import InputPatterns from "../utils/constants/input-patterns";


export default function UpdatePassword(){

    const { updatePasswordRequest } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const [isSbumiting, setIsSubmiting] = useState(false)
    const username = useMemo( () => (location.state as any|| 'bennyibmn@gmail.com') as string, [location])
    const [serverErrorCode, setServerErrorCode] = useState<number>()
    const ErrorArea = useCallback( () => {
        if(!serverErrorCode){
            return <></>
        }

        if(serverErrorCode === HttpStatusCode.UNAUTHORIZED){
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm">le code saisit n'est pas valide! <br/>veuillez en refaire la demande</span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }else if( serverErrorCode === HttpStatusCode.FORBIDEN ){
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-yellow-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-yellow-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm">Veuillez valider/activer votre compte</span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }else{
            return (
                <div className="relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                    <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                    <span className="text-white text-sm">impossible de se connecter, vérifier votre connexion internet</span>
                    <i onClick={ () => setServerErrorCode(undefined)} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                </div>
            )
        }
    } , [serverErrorCode])

    const onSubmit = useCallback( (updatePasswordDto : UpdatePasswordDto) => {

        setIsSubmiting(true)
        setServerErrorCode(undefined)
        updatePasswordRequest( updatePasswordDto )
        .then( () => navigate("/auth", { state : { updateSucceed : true}}) )
        .catch( err => setServerErrorCode(err?.response?.status || 500))
        .finally( () => setIsSubmiting(false) )
    }, [updatePasswordRequest, navigate])

    
    useEffect( () => {
        document.title = 'SA-HD | Réinitialisation Mot de passe'
    }, [])
    
    
    return (
        <Section
            className="h-screen flex"
            container={false}
        >
            <div className="h-full hidden md:flex md:flex-col items-end pt-48 pr-8 w-[40%] bg-secondary bg-[url(/src/assets/images/painting-splash.png)] bg-cover bg-no-repeat bg-right">
                <Logo className="w-60"/>
                {/* <img src="/images/red-car.png" alt="" /> */}
            </div>
            <div className="h-full w-full md:w-[60%] bg-secondary bg-opacity-90 flex flex-col justify-center px-4 sm:px-8 2xl:px-20">
                <img src="/images/logo.webp" className="md:hidden object-contain w-52 block mx-auto my-12" alt="" />
                <h1 className="text-2xl sm:text-3xl lg:text-5xl text-white font-semibold">Mise à jour du mot de passe</h1>
                <ErrorArea />

                <MyForm

                    className="w-full md:w-full"
                    fields={ {
                        username : {
                            placeholder : 'mail@icashbs.com',
                            label : 'Email (figé)',
                            id : 'email',
                            required : true,
                            readonly : true,
                            pattern : InputPatterns.EMAIL,
                            initialValue : username,
                            onEmptyErrorMessage : 'champ obligatoire',
                            onInvalidErrorMessage : 'adresse email invalid!'
                        },
                        code : {
                            id: "resetCode",
                            label : "Code de réinitialisation (via email)",
                            placeholder : "trouver le code dans le mail de réinitialisation",
                            onEmptyErrorMessage : 'champ obligatoire',
                            onInvalidErrorMessage : "le code saisit n'est pas valide"                        
                        },
                        password : {
                            placeholder : 'nouveau mot de passe',
                            label : 'Nouveau mot de passe',
                            id : 'newPassword',
                            required : true,
                            type : FieldInputType.PASSWORD,
                            pattern : InputPatterns.PASSWORD,
                            onEmptyErrorMessage : 'champ obligatoire',
                            onInvalidErrorMessage : 'le mot de passe doit compter aumoins 8 characters et contenir les lettres (minuscule et majuscule) et  aumoins un character special'
                        },
                        // confirmPassword : {
                        //     placeholder : 'confirmer mot de passe',
                        //     label : 'Confirmer le mot de passe',
                        //     id : 'confirmPassword',
                        //     required : true,
                        //     type : FieldInputType.PASSWORD,
                        //     pattern : InputPatterns.PASSWORD,
                        //     onEmptyErrorMessage : 'champ obligatoire',
                        //     onInvalidErrorMessage : 'le mot de passe ne correspond pas'
                        // }
                    }}
                    renderFieldComponent={InputFieldTemplate2Big}
                    onSubmit={fields => onSubmit(fields as any)}
                >

                    <Button 
                        className='text-xl py-3 w-full flex items-center justify-center gap-2 rounded-md shadow-md' 
                        disabled={isSbumiting}
                    >
                        <span>metre a jour</span>
                        { isSbumiting ? <span className='fa-solid fa-circle-notch animate-spin' /> : <i className="fas fa-upload" />  }
                    </Button>

                    <Link to='/auth' className="block w-fit my-2 mx-auto text-gray-200 hover:text-gray-400 underline underline-offset-1">connectez-vous</Link>

                </MyForm>
            </div>
        </Section>
    )
}