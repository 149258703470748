import { useCallback, useEffect } from 'react'

export type ModalProps = {
    onOutsideClick? : () => void
    onCloseBtnClick? : () => void
    showCloseButton? : boolean
    closeAfterLapse? : number
    children? : any
    className? : string
    contentClassName? : string
}

export default function Modal({className = '', contentClassName = '', showCloseButton = false,onCloseBtnClick, onOutsideClick, children} : ModalProps) {
    
    const handleClick = useCallback( (e : any) => {
        // e.stopPropagation(); 
        // onOutsideClick && onOutsideClick()
    }, [onOutsideClick])


    const handleCloseBtnClick = useCallback( (e : any) => {
        // e.stopPropagation();
        onCloseBtnClick && onCloseBtnClick()
    }, [onCloseBtnClick])

    useEffect( () => {
        document.body.classList.add('overflow-y-hidden')
        document.body.style.height = '110vh'
        
        return () => {
            document.body.classList.remove('overflow-y-hidden')
            document.body.style.height = ''
        }
    }, [])


    return(
        <div className='bg-[rgba(0,0,0,.8)] fixed inset-0 z-[2000] h-screen flex justify-center items-center' onClick={handleClick}>
            <div 
                className={`min-w-[10rem] sm:min-w-[25rem]  ${contentClassName} max-h-[90vh] rounded-sm pt-6 pb-4 px-2 m-0 bg-white relative shadow-sm shadow-white ${className}`}
                // onClick={ e => e.stopPropagation()}
            >
                {showCloseButton && (
                    <button 
                        className='fas fa-close absolute -top-6 right-2 z-10 w-12 h-12 rounded-full bg-gray-100 flex justify-center items-center text-xl shadow-md hover:text-2xl hover:text-white hover:bg-red-500 hover:shadow-none' 
                        onClick={handleCloseBtnClick}
                    />
                )}
                {children}
            </div>
        </div>
    )
};
