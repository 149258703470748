

const DUMMY_DELIVERING = [
    {
        label : 'Livraison',
        value : 'livraison'
    },
    {
        label : 'Rammassage',
        value : 'rammassage'
    },
]

export default DUMMY_DELIVERING