import { createContext, ReactNode} from "react"
import AuthContextProvider from "./auth"
import LayoutContextProvider from "./layout"
export type GlobalContextProps = {
     
}

export const GlobalContext = createContext<GlobalContextProps>({setAuthSession : () => undefined})

export default function GlobalContextProvider ( {children} : {children : ReactNode}) {

    return(
        <GlobalContext.Provider value={{}}>
            <LayoutContextProvider>
                <AuthContextProvider>
                    {children} 
                </AuthContextProvider>
                
            </LayoutContextProvider>
        </GlobalContext.Provider>
    )
}
