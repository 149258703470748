import { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useApiCrud } from "../../hooks"
import { Order, ResponseModel } from "../../models"
import { formatDate, getCurrentDate, isDateBetween } from "../../utils/helpers/date-time"
import { Button, MyForm, OrderDetails } from "../../components"
import { FieldValidity, InputFieldTemplate2Small, InputFieldTemplatePeriod } from "../../components/my-form"
import { formatNumber } from "../../utils/helpers/others"
// import { getFromLocalStorage } from "../../utils/helpers/local-storage"

type States = {
    orders? : ResponseModel<Order>
    currentOrder? : Order
    isLoading? : boolean
    isError? : boolean,
    isEmptyList? : boolean
    page : number
    hasMorePage? : boolean
    search? :any
    searchOptions? : {ref : string, po : string, orderNum : string,  period : {start : Date, end : Date}}
}

export default function OrdersHistoryPage() {
    
    const {findAll} = useApiCrud<Order>('orders')

    const [states, setStates] = useState<States>({
        page : 1,
        // orders : {
        //     currentPage : 1,
        //     totalElements : 10,
        //     totalPages : 10,
        //     contents : getFromLocalStorage("orders") || []
        // }
    })

    const fetchHistory = useCallback( () => {
        setStates( prev => ({...prev, isLoading : true, isError : false, isEmptyList : false}))
        setTimeout( () => {
            findAll({size : Number.POSITIVE_INFINITY , search : states.search})
            .then( response => setStates( prev => ({ ...prev,  orders : response,  isEmptyList : response.totalElements === 0, })))
            .catch( err => setStates( prev => ({...prev, isError : true})) )
            .finally( () => setStates( prev => ({...prev, isLoading : false})) )
        }, 1500)

    }, [findAll, states.search])

    const filterOrders = useMemo( () => {
        const orders = states.orders?.contents || []

        if(!states.searchOptions){
            return orders 
        }

        return orders.filter( (order : any) => {
            let shouldTake = true
            if(states.searchOptions?.po){
                shouldTake = `${order.po}`.toLowerCase().includes(states.searchOptions?.po?.toLowerCase())
            }

            if(states.searchOptions?.orderNum){
                shouldTake &&= `${order._id}`.toLowerCase().includes(states.searchOptions?.orderNum?.toLowerCase())
            }

            if(states.searchOptions?.ref){
                shouldTake &&= `${order.ref}`.toLowerCase().includes(states.searchOptions?.ref?.toLowerCase())
            }
            
            const isBetween = isDateBetween(order.metadata?.created.date!, states.searchOptions?.period!)
            return shouldTake && isBetween
        })
    }, [states.orders, states.searchOptions])

    const Loader = useCallback( () => !states.isLoading ? <></> : (
        <tr>
            <td colSpan={5} className="text-center py-8">
                <i className="fa-solid fa-spinner animate-spin text-4xl text-primary" />
                <h1>chargement</h1>
            </td>
        </tr>
    ), [states.isLoading])

    const EmptyList = useCallback( () => states.isLoading || states.isError || states.orders?.totalElements ? <></> : (
        <tr>
            <td colSpan={5} className="text-center py-8">
                <img src="/images/empty-list.png" alt="" />
                <h1>{states.searchOptions ? "Aucune ligne ne correspond aux criteres de recherche" : "la liste est actuellement vide"}</h1>
            </td>
        </tr>
    ), [states.isError, states.isLoading, states.orders?.totalElements, states.searchOptions])

    const Error = useCallback( () => !states.isError ? <></> : (
        <tr>
            <td className="group text-center py-8" colSpan={5} onClick={ () => fetchHistory()}>
                <i className="fas fa-bug text-5xl text-gray-300 group-hover:text-primary" />
                <br />
                <button className="mt-4 group-hover:text-primary">cliquer pour réessayer !</button>
            </td>
        </tr>
    ), [states.isError, fetchHistory])

    const Orders = useCallback( () => {
        if( states.isLoading || states.isEmptyList || states.isError){
            return <></>
        }else{
            return (
                <>
                    {filterOrders.reverse().map( (order : any) => (
                        <tr className=" odd:bg-gray-200" key={order._id}>
                            <td className="p-2 max-w-[6rem] sm:max-w-none">{ formatDate(order.metadata?.created?.date) }</td>
                            <td className="p-2 max-w-[7rem] sm:max-w-none break-all">{order._id}</td>
                            <td className="p-2 max-w-[6rem] w-24 sm:w-36 break-all">{order.po}</td>
                            <td className="p-2 hidden sm:block w-24 break-all">{order.ref}</td>
                            <td className="p-2">{formatNumber(order.totalAmount)}</td>
                            <td className="p-2">
                                <Link to="/order-details" state={order} className="underline underline-offset-2 text-primary hover:text-primary-deep cursor-pointer" >details</Link>
                                
                            </td>
                        </tr>
                    ))}
                </>
            )
        }
    }, [filterOrders, states.isEmptyList, states.isError, states.isLoading])


    useEffect( () => {
        fetchHistory()
    }, [fetchHistory])

    useEffect( () => {
        document.title = 'SA-HD | Historique des commandes'
    }, [])
    
    return(
        <div className="z-10 relative md:w-[700px] xl:w-[1000px] mx-2 sm:mx-4 md:mx-auto">
            <h1 className="text-white text-2xl sm:text-4xl lg:text-6xl -translate-y-20 lg:-translate-y-28 text-center">
                Historique de commande
            </h1>

            <MyForm
                className="px-4 sm:px-0 md:px-2 w-full flex flex-row flex-wrap justify-between sm:gap-4 items-end my-8"
                fields={{
                    period : {
                        label : 'Dans la période',
                        renderFieldComponent : InputFieldTemplatePeriod,
                        initialValue : { start : "", end : getCurrentDate()}
                    },
                    orderNum : {
                        label : '# de com./fact.',
                    },
                    po : {
                        label : '# de PO',
                    },
                    ref : {
                        label : 'Réference',
                    }
                }}
                onSubmit={ fields => setStates( prev => ({...prev, searchOptions : fields as any}))}
                renderFieldComponent={ props => (
                    <div className={"w-[49%] sm:w-32 "}>
                        <div className="">
                            <label className="text-gray-500 text-lg" htmlFor={props.id}>{props.label}</label>
                            { props.hasError && (
                                <p className='text-red-500 text-xs'>
                                    { (props.validity === FieldValidity.EMPTY) ? props.onEmptyErrorMessage : props.onInvalidErrorMessage }
                                </p>
                            )}
                        </div>
                        <input  
                            className='w-full p-2 h-8  border rounded-sm'
                            id={props.id}
                            type={props.type} 
                            name={props.name} 
                            onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})} 
                            placeholder={props.placeholder}
                            defaultValue={props.initialValue}
                            readOnly={props.readonly} 
                        />
                    </div>
                )}
            >
                <Button className=" flex items-center justify-center gap-2 py-2 px-2 rounded">
                    <span className="sm:hidden">Filtrer</span>
                    <i className="fas fa-search"></i>
                </Button>

            </MyForm>

            <div className="flex items-center justify-between px-4 sm:px-2 md:px-0">
                <div>
                    { states.searchOptions && (
                        <p>
                        Filtre appliqué, annuler et <span className="text-primary cursor-pointer underline" onClick={() => document.location.reload()}>Afficher tout</span>
                    </p>
                    )}
                </div>
                <Button className="group rounded-md" link="/new-order">
                    Ajouter <i className="fas fa-add group-hover:rotate-180" />
                </Button>
            </div>

            <div className="">

            </div>

            <table className="border w-full mt-4 mb-12 md:text-lg">
                <thead>
                    <tr className="uppercase bg-slate-600 text-white">
                        <th className="py-1 pl-2 text-left w-24 sm:w-40 ">Date <span className="hidden sm:inline">d'entrée</span></th>
                        <th className="py-1 pl-2 text-left max-w-[7rem] sm:max-w-none ">Num. <span className="hidden sm:inline">commande</span></th>
                        <th className="py-1 pl-2 text-center md:text-left w-24 sm:w-40">PO</th>
                        <th className="py-1 pl-2 hidden sm:block text-left">Réf<span className="hidden sm:inline">erence</span></th>
                        <th className="py-1 pl-2 text-left">Total</th>
                        <th className="w-14"></th>
                    </tr>
                </thead>

                <tbody>
                    <Error />
                    <Loader />
                    <EmptyList />
                    <Orders />
                </tbody>
            </table>

            { states.currentOrder && <OrderDetails order={states.currentOrder} onClose={ () => setStates(prev => ({...prev, currentOrder : undefined})) } /> }
        </div>
    )
};
