import { createContext, ReactNode, useEffect, useMemo, useState } from "react"
import { User, LoginResponse } from "../../models"
import Constants from "../../utils/constants/constants"

import { deleteFromLocalStorage, getFromLocalStorage, saveToLocalStorage } from "../../utils/helpers/local-storage"

export type GlobalContextProps = {
    user?: User,
    accessToken? : string,
    setAuthSession : React.Dispatch<React.SetStateAction<LoginResponse | undefined>>,
    sessionHasExpired? : boolean
    setSessionExpired : React.Dispatch<React.SetStateAction<boolean | undefined>>,
    userMenu? : {label : string, link : string}[]
}
export const AuthContext = createContext<GlobalContextProps>({
    setAuthSession: () => {},
    setSessionExpired : () => {}
})

export default function AuthContextProvider ( {children} : {children : ReactNode}) {

    const [authSession, setAuthSession] = useState( getFromLocalStorage<LoginResponse>( Constants.AUTHENTICATED_USER ) )
    const [sessionHasExpired, setSessionExpired] = useState<boolean | undefined>( )
    const user = useMemo<User | undefined>( () => authSession ? authSession.user : undefined, [authSession])
    const accessToken = useMemo( () => authSession ? authSession.token : undefined, [authSession] )

    useEffect( () => {
        if(!authSession){
            deleteFromLocalStorage( Constants.AUTHENTICATED_USER )
        }else{
            setSessionExpired(false)
            saveToLocalStorage( Constants.AUTHENTICATED_USER, authSession )
        }
    }, [authSession])
    
    return(
        <AuthContext.Provider value={{
            user, 
            accessToken, 
            setAuthSession,
            sessionHasExpired,
            setSessionExpired
        }}>
            {children}
        </AuthContext.Provider>
    )
}