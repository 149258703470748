import { Link } from "react-router-dom"
import { UserProfile, Logo, Container } from "."

export default function Header() {
    
    return (
        <header className="py-2 bg-secondary fixed z-30 w-full shadow-lg">
            <Container className="flex justify-between">
                <Logo />
                <nav className="hidden md:flex gap-6">
                    <Link to="/" className="text-white hover:text-red-600 hover:after:w-full">Accueil</Link>
                    <Link to="history" className="text-white hover:text-red-600 hover:after:w-full">Historique</Link>
                    <Link to="new-order" className="text-white hover:text-red-600 hover:after:w-full">Nouvelle Commande</Link>
                </nav>
                <UserProfile />
            </Container>
        </header>
    )
};
