import { useNavigate } from "react-router-dom";
import HttpStatusCode from "../../utils/constants/http-status-code";
import useAuth from "../use-auth";
import useApi from "./use-api";
import { useEffect } from 'react';

export default function useApiSecured(path : string, baseRoute? : string) {
    const navigate = useNavigate()
    const {accessToken, user, logout} = useAuth()
    const api = useApi(path)

    useEffect( () => {
        api.addRequestInterceptor( config => ({
            ...config,
            headers : { 
                ...config.headers,
                sessionId : accessToken!,
                "Authorization" : `Bearer ${accessToken}`,
            } 
        }) )

        api.addResponseInterceptor( onSuccess => onSuccess, error => {
            if(error?.response?.status === HttpStatusCode.FORBIDEN){
                logout(true)
            }
        })
    }, [accessToken, api, api.addRequestInterceptor, logout, navigate, user?.customerName, user?.customerNumber, user?.username])

    return api
};
