import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { Button, Container, OrderItemComponent, OrderNoteComponent, SelectBoxWithLIconsLabel } from "../../components";
import CustomSelectBox from "../../components/custom-select-box";
import { formatDate, getCurrentDate } from '../../utils/helpers/date-time';
import { Product } from "../../models";
import useApiCrud from '../../hooks/api/use-api-crud';
import { OrderItem } from "../../models/order";
import DUMMY_DELIVERING from "../../utils/helpers/dummy-data/dummy-delivering";
import { AuthContext } from "../../contexts/context/auth";
import { formatNumber } from "../../utils/helpers/others";

type States = {
    items : (OrderItem | undefined)[]
    productList? : Product[]
    isSubmiting? : boolean
    isError? : boolean
    saveStatus? : any
    totalAmount : number
    search? : string
    page : number
    isLoadingData? : boolean,
    isPoError? : boolean
    isDeliveringError? : boolean,
    isWarehouseError? : boolean
    isFormValid : boolean
    showCancelConfirmation? : boolean

}

export default function NewOrderPage() {

    const {user} = useContext(AuthContext)
    const navigate = useNavigate()
    const [productList, setProductList] = useState<Product[]>()
    const {findAll : findAllProducts} = useApiCrud<Product>('products')
    const {save : saveOrder} = useApiCrud<any>("orders")
    const [states, setStates] = useState<States>( {
        items : [undefined],
        totalAmount : 0,
        page : 1,
        isFormValid : true
    })
    const isProductListNotEmpty = useMemo( () => (states.items.filter( orderItem => orderItem !== undefined).length > 0), [states.items])
    
    const order = useRef<any>({ client : user?.email })    
    const hasComeToRemove = useRef(false)

    const fetchProducts = useCallback( () => {
        setStates( prev => ({...prev, isLoadingData : true}))
        findAllProducts({page : states.page, size : 100, search : states.search})
        .then( response => setProductList( prev => (states.page === 1) ? response.contents : [ ...(prev || []) , ...(response.contents || [])]))
        .finally( () => setStates( prev => ({...prev, isLoadingData : false})))
    }, [findAllProducts, states.page, states.search])

    const checkFormValidity = useCallback( () => {
        const errors : any = {}
        if(!order.current.po){
            errors.isPoError = true
        }else{
            errors.isPoError = false
        }

        if(!order.current.delivering){
            errors.isDeliveringError = true
        }else{
            errors.isDeliveringError = false
        }
        
        if(!order.current.warehouse){
            errors.isWarehouseError = true
        }else{            
            errors.isWarehouseError = false
        }

        const isFormValid = !(errors.isWarehouseError || errors.isDeliveringError || errors.isPoError)
        setStates( prev => ({...prev, isFormValid, ...errors}))
        return isFormValid
    }, [])

    const onSubmit = useCallback( (e : any) => {
        e.preventDefault()

        order.current.items = states.items.filter( item => item !== undefined)
        order.current.totalAmount = states.totalAmount

        setStates( prev => ({...prev, isSubmiting : true, isError : false}))
        saveOrder( {...order.current, ...user} )
        .then( () => navigate('/history') )
        .catch( () => setStates( prev => ({...prev, isError : true})) )
        .finally( () => setStates( prev => ({...prev, isSubmiting : false})) )
        
    }, [navigate, saveOrder, states.items, states.totalAmount, user])

    const handleProductListChange = useCallback( (oldItem : OrderItem | undefined, newItem : OrderItem) => {
        if(hasComeToRemove.current){
            hasComeToRemove.current = false
            return
        }else{
            setStates( prev => ({
                ...prev,
                items : prev.items.map( item => (item === oldItem) ? newItem : item ),
                totalAmount : prev.items.map( item => (item === oldItem) ? newItem : item )?.map( item => item?.subTotalTTC || 0 ).reduce( (sum : number, current : number) => sum + current, 0 )
            }))
        }
    } , [])

    const addItem = useCallback( () => {
        setStates( prev => ({
            ...prev, 
            items : [...prev.items, undefined]
        }))
    }, [])

    const handleRemoveOrderItem = useCallback( (orderItem? : OrderItem) => {
        hasComeToRemove.current = true
        setStates( prev => {
            const items = prev.items.filter( item => item !== orderItem )
            return { 
                ...prev, 
                items : items.length ?  items : [undefined],
                totalAmount : prev.totalAmount - (orderItem?.subTotalTTC || 0)
            }
        })
    }, [])

    useEffect( () => {
        fetchProducts()
    }, [fetchProducts])
    
    useEffect( () => {
        if(states.isSubmiting){
            document.body.classList.add("overflow-hidden")
        }else{
            document.body.classList.remove("overflow-hidden")
        }

        return () => document.body.classList.remove("overflow-hidden")
    }, [states.isSubmiting])

    useEffect( () => {
        document.title = 'SA-HD | Nouvelle commande'
    }, [])

    return(
        <div className="z-10 relative md:w-[700px] xl:w-[1000px] mx-2 sm:mx-4 md:mx-auto">
            <h1 className="text-white text-2xl sm:text-4xl lg:text-6xl -translate-y-20 lg:-translate-y-28 text-center">
                Enregistrer une commande
            </h1>
            <form onSubmit={onSubmit} className='w-full mb-20' onClick={ e => e.preventDefault()}>
                <div className="w-full">

                    {/* head */}
                    <div className="py-4 flex justify-between items-center border-b">
                        <div className=" ">
                            <h3>Nouvelle commande</h3>
                            <span className="text-sm">{ formatDate(getCurrentDate())} </span>
                        </div>

                        {/* <div className="flex flex-col">
                            <label htmlFor="po" className="text-sm">PO*</label> { states.isPoError && <span className="text-xs text-primary">Champ recquis</span>}
                            <input
                                placeholder="purchase number"
                                type="text" 
                                className="p-1 border rounded placeholder:text-sm placeholder:text-slate-300"
                                onChange={ e => { order.current.po = e.target.value } }
                            />
                        </div> */}

                        <Button className="rounded-md px-4 py-2 h-fit w-fit bg-slate-500" onClick={() => document.location.reload() }>Recommencer</Button>
                    </div>

                    {/* main */}
                    <div className="w-full p-0 sm:grid sm:grid-cols-2 items-center gap-6 md:gap-8  lg:gap-40 justify-between my-6" >
                        <div className="flex-1 flex flex-col gap-4 w-full">
                            <div className='flex items-center gap-4 h-fit w-full  '>
                                <div className="flex items-center justify-center">
                                    <i className={`text-4xl text-primary fas fa-user`} />
                                </div>
                                <div className="flex flex-col w-full">
                                    <span className="font-bold">Client</span>
                                    <label className="flex items-center h-11 py-1 pl-2 border rounded bg-slate-100" >{`${user?.firstname} ${user?.lastname}`}</label>
                                    
                                </div>
                            </div>
                            
                            <SelectBoxWithLIconsLabel
                                id='delivering' 
                                label="Livraison"
                                placeholder=""
                                icon='fas fa-car'
                                value={ order.current?.delivering}
                                options={ DUMMY_DELIVERING }
                                onChange={ value => { order.current.delivering = value }}
                                hasError={ states.isDeliveringError }

                            />
                            <SelectBoxWithLIconsLabel
                                id='warehouse' 
                                label="Entrepot"
                                placeholder=""
                                icon='fas fa-house'
                                value={ order.current?.warehouse}
                                options={ [{label : "Quebec", value : "quebec"}, {label : "Saguenay", value : "saguenay"}] } // DUMMY_WAREHOUSE.map( warehouse => ({label : warehouse.name, value : warehouse._id})) }
                                onChange={ value => { order.current.warehouse = value }}
                                hasError={ states.isWarehouseError }
                                
                            />
                        </div>

                        <div className=" mt-4 sm:mt-0 h-full flex-1 flex flex-col gap-y-4 justify-between">
                            <div className='flex items-center gap-4 h-fit w-full  '>
                                <div className="sm:hidden flex items-center justify-center">
                                    <i className={`text-4xl text-primary fas fa-user`} />
                                </div>
                                <div className="w-full flex flex-col">
                                    <label htmlFor="po" className="font-bold">PO*</label> { states.isPoError && <span className="text-xs text-primary">Champ recquis</span>}
                                    <input
                                        placeholder="purchase number"
                                        type="text" 
                                        className="p-1 h-11 border rounded placeholder:text-sm placeholder:text-slate-600"
                                        onChange={ e => { order.current.po = e.target.value } }
                                    />
                                </div>
                            </div>
                            
                            <OrderNoteComponent onChange={ (noteName, noteValue) => { order.current.notes = { ...order.current.notes, [noteName] : noteValue } } } />
                        </div>

                    </div>

                </div>

                {/* products */}
                <div className="w-full border">
                    {/* list-header */}
                    <div className="uppercase flex gap-2 md:gap-4 lg:gap-8 p-2 bg-gray-600 text-white sm:font-semibold">
                        <div className=" w-36 sm:w-56 pl-2">
                            <span>Produit</span>
                        </div>
                        <div className="hidden xl:block w-80 pl-2">
                            <span>Description</span>
                        </div>
                        <div className="w-10 sm:w-16 pl-1 sm:pl-2">
                            <span>Qté</span>
                        </div>
                        <div className="hidden  pl-2 w-24">
                            <span>Disponibilté</span>
                        </div>
                        <div className="hidden pl-2 w-12">
                            <span>B/O</span>
                        </div>
                        <div className="w-16 2xl:w-28 pl-1 sm:pl-2">
                            <span>Prix</span>
                        </div>
                        <div className="pl-1 sm:pl-2 w-24 hidden sm:block">
                            <span>TPS/TVQ</span>
                        </div>
                        <div className="pl-1 sm:pl-2 flex-1 min-w-[4rem]">
                            <span>Total</span>
                        </div>
                    </div>
                    <div>
                        { states.items?.map( (item, index) => (
                            <OrderItemComponent
                                onSearchProduct={search => setStates( prev => ({...prev, page : 1, search}))}
                                onEndProductsListReached={ () => !states.search && setStates( prev => ({...prev, page : prev.page + 1}))}
                                key={index}
                                productList={productList?.filter( product => (product._id === item?._id) || !states.items.map( it => it?._id).includes(product?._id ) )}
                                item={item}
                                onChange={handleProductListChange}
                                removeItem={handleRemoveOrderItem}
                                addItem={addItem}
                                isLoadingData={ states.isLoadingData}
                            />
                        )) }
                    </div>
                    <div className="flex justify-between gap-2 md:gap-4 lg:gap-8 py-6 px-2  font-semibold ">
                        <div className="h-fit flex-1 flex flex-wrap gap-4 sm:gap-8">
                            
                            {
                                isProductListNotEmpty && (
                                    <>

                                        <Button 
                                            className="py-1 px-3 shadow-xl bg-green-600 hover:bg-green-800"
                                            disabled={states.isSubmiting}
                                            preventDefault
                                            onClick={ e => checkFormValidity() && onSubmit(e)}
                                        >
                                            Soumettre <i className="fas fa-save" />
                                        </Button>
                                    </>
                                )
                            }
                            <Button onClick={ e => {
                                if(isProductListNotEmpty){
                                    setStates( (prev => ({ ...prev, showCancelConfirmation : true})))
                                }
                            } } className="py-1 px-3 bg-red-600 hover:bg-red-800 shadow-md" link={ isProductListNotEmpty ? "" : "/history"}>
                                Annuler <i className="fas fa-trash" />
                            </Button>

                        </div>
                        <div className="flex flex-col  items-center">
                            <div className="pb-2 flex items-center gap-x-2">
                                <span className="text-sm w-24 font-normal ">SOUS TOTAL.</span>
                                <span className="flex min-w-[4rem] font-semibold"> { formatNumber(states.items.map( ite => (ite?.price || 0) * (ite?.qte || 0)).reduce( (prev, curr) => prev + curr)) } </span>

                            </div>
                            <div className="py-2 border-y-2 border-gray-600 flex items-center gap-x-2">
                                <span className="text-sm w-24 font-normal ">TPS/TVQ.</span>
                                <span className="flex min-w-[4rem] font-semibold"> { formatNumber(states.items.map( ite => ite?.taxeEnv || 0).reduce( (prev, curr) => prev + curr)) } </span>

                            </div>
                            <div className="pt-2 flex items-center gap-x-2">
                                <span className="text-sm w-24 font-normal ">TOTAL.</span>
                                <span className="flex min-w-[4rem] font-semibold"> { formatNumber(states.totalAmount) } </span>

                            </div>

                        </div>
                    </div>
                </div>
                
                {states.isError && (
                    <div className=" mx-auto relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                        <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                        <span className="text-white text-sm"> Impossible d'enregistrer! réessayer plus tard </span>
                        <i onClick={ () => setStates( prev => ({...prev, isError : false}))} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                    </div>
                )}
                {!states.isFormValid && (
                    <div className=" mx-auto relative animate-pulse w-fit flex items-center mt-5 p-4 gap-4 bg-red-600 rounded text-white">
                        <i className="fas fa-bug bg-white text-red-600 flex justify-center items-center w-8 h-8 rounded-full"></i>
                        <span className="text-white text-sm"> Remplissez tout champs recquis! </span>
                        <i onClick={ () => setStates( prev => ({...prev, isFormValid : true}))} className="fas fa-close text-xs cursor-pointer bg-red-700 text-white hover:bg-red-800 shadow-2xl w-5 h-5 flex justify-center items-center rounded-full absolute -top-2 -right-2" />
                    </div>
                )}
            </form>
            
            {
                states.showCancelConfirmation && (
                    <div className="fixed z-50 top-0 left-0 w-screen min-h-screen bg-black bg-opacity-80 flex flex-col gap-8 justify-center items-center">
                        <div className="text-center text-sm sm:text-base w-72 sm:w-96 bg-white relative p-2 sm:p-4">
                            <h3 className="text-center font-semibold text-primary">Annuler la commande ?</h3>
                            <p className="my-4">
                                Vous étes sur le point d'annuler la commande. Notez que si vous confirmer, cette commande ne sera pas enregistrée!
                            </p>

                            <div className="flex justify-between flex-wrap">
                                <Link className="w-[48%] p-2 sm:px-4 bg-gray-300 hover:bg-red-700 hover:text-white" to="/history">Oui</Link>
                                <button className="w-[48%] p-2 sm:px-4 bg-green-300 hover:bg-green-700 hover:text-white" onClick={() => setStates( (prev => ({ ...prev, showCancelConfirmation : false})))}>Non</button>
                            </div>

                        </div>
                    </div>
                )
            }

            {
                states.isSubmiting && (
                    <div className="fixed z-50 top-0 left-0 w-screen h-screen bg-black bg-opacity-80 flex flex-col gap-8 justify-center items-center">
                        <i className="fa-solid fa-spinner animate-spin text-8xl text-primary" />
                        <p className="text-white">
                            loading ...
                        </p>
                    </div>
                )
            }
        </div>
    )
};



