import { Order } from "../models";
import { formatDate } from "../utils/helpers/date-time";
import { formatNumber } from "../utils/helpers/others"
import Modal from "./modal";

type props = {
    order : Order
    onClose? : () => void
}
export default function OrderDetails({order, onClose} : props){

    return(
        <Modal
            showCloseButton
            onCloseBtnClick={onClose}
            contentClassName='max-w-full sm:max-w-[30rem] md:max-w-[45rem] lg:max-w-[55rem] pb-0 md:px-8'
        >
            <h1 className="text-center text-xl sm:text-2xl md:text-3xl pb-2 mb-4 border-b">Details de commande</h1>
            
            <div className="grid sm:grid-cols-2 gap-x-8 gap-y-4">
                <div className="">
                    <h3 className=" text-slate-600">Date de création</h3>
                    <span className="text-sm">{ formatDate(order.metadata?.created?.date) }</span>
                </div>
                <div className="">
                    <h3 className=" text-slate-600">Po</h3>
                    <span className="text-sm">{order.po}</span>
                </div>
                <div className="">
                    <h3 className=" text-slate-600"># Commande</h3>
                    <span className="text-sm">{order.orderNum}</span>
                </div>
                <div className="">
                    <h3 className="text-slate-600">Etat commande</h3>
                    <span className="text-sm">{order.status}</span>
                </div>
            </div>

            <table className="w-full mt-6">
                <thead>
                    <tr className="bg-slate-600 text-white">
                        <th className="font-normal py-1 pl-2 text-left">Produit</th>
                        <th className="font-normal py-1 text-left">Qté</th>
                        <th className="font-normal py-1 pl-2 text-left">S.Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        order.items.map( item => (
                            <tr className="border-b" key={item?._id}>
                                <td className="pl-2 text-left text-sm py-2 max-w-[10rem] truncate">{item?.title}</td>
                                <td className="text-left text-sm py-2">{item?.qte}</td>
                                <td className="text-left text-sm py-2">{formatNumber(item?.subTotalTTC)}</td>
                            </tr>
                        ))
                    }
                </tbody>
                <tfoot>
                    <tr className="border-t-4 text-xl">
                        <td colSpan={2} className="">Total</td>
                        <td className="py-3">{formatNumber(order.totalAmount)}</td>
                    </tr>
                </tfoot>
            </table>
        </Modal>
    )
}