import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { formatDate } from "../../utils/helpers/date-time"
import { formatNumber } from "../../utils/helpers/others"
import { Button } from "../../components"
import { Order } from "../../models"

export default function OrderDetailsPage() {
    const order = useLocation().state as any as Order
    useEffect( () => {
        document.title = 'SA-HD | Details de commande'
    }, [order])
    
    return(
        <div className="z-10 pb-12 relative">
            <h1 className="text-white text-2xl sm:text-4xl lg:text-6xl -translate-y-20 lg:-translate-y-28 text-center">
                Détails de commande
            </h1>

            <div className="md:w-[700px] xl:w-[1000px] mx-2 sm:mx-4 md:mx-auto flex flex-col items-start gap-y-10">
                <Button className="flex items-center gap-x-2" link="/history"> <i className="fas fa-arrow-left" /> retour</Button>
                <div className="font-bold uppercase md:text-lg  grid grid-cols-2 md:grid-cols-3 justify-between gap-x-14 gap-y-4 xl:px-0">
                    <div className="w-fit">
                        <h3 className=" text-slate-600">Date de création</h3>
                        <span className="text-sm font-semibold">{ formatDate(order.metadata?.created?.date) }</span>
                    </div>
                    <div className="w-fit">
                        <h3 className=" text-slate-600">Po</h3>
                        <span className="text-sm font-semibold">{order.po}</span>
                    </div>
                    <div className="w-fit">
                        <h3 className=" text-slate-600">Num. commande</h3>
                        <span className="text-sm font-semibold">{order._id}</span>
                    </div>
                    <div className="">
                        <h3 className="text-slate-600">Réference</h3>
                        <span className="text-sm font-semibold">{order.ref}</span>
                    </div>
                    <div className="">
                        <h3 className=" text-slate-600">Livraison</h3>
                        <span className="text-sm font-semibold">{order.delivering}</span>
                    </div>
                    <div className="">
                        <h3 className="text-slate-600">Entrepot</h3>
                        <span className="text-sm font-semibold truncate">{order.warehouse}</span>
                    </div>
                </div>

                <div className="flex flex-col gap-y-4 text-slate-600">
                    <div className="">
                        <h3 className="font-bold uppercase md:text-lg">Mes notes</h3>
                        <span className="text-sm">{order.notes?.ownerNote || "Aucune note enregistrée"}</span>
                    </div>
                    <div className="">
                        <h3 className="font-bold uppercase md:text-lg">Note à Auto-HD</h3>
                        <span className="text-sm">{order.notes?.transitNote || "Aucune note enregistrée"}</span>
                    </div>
                </div>

                <table className="w-full sm:text-lg upp">
                    <thead className="">
                        <tr className="uppercase bg-slate-600 text-white">
                            <th className="font-normal py-1 pl-2 text-left">Produit</th>
                            <th className="font-normal py-1 text-left">Qté</th>
                            <th className="font-normal py-1 text-left">Prix</th>
                            <th className="font-normal py-1 text-left">TPS/TVQ</th>
                            <th className="font-normal py-1 text-left">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order.items.map( (item : any) => (
                                <tr className="border-b" key={item?._id}>
                                    <td className="pl-2 text-left py-2 max-w-[7rem] sm:max-w-fit truncate">{item?.title}</td>
                                    <td className="text-left py-2">{item?.qte}</td>
                                    <td className="text-left py-2">{formatNumber(item?.price)}</td>
                                    <td className="text-left py-2">{formatNumber(item?.taxeEnv)}</td>
                                    <td className="text-left py-2">{formatNumber(item?.price * item?.qte)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                        <tr className="border-t-4">
                            <td colSpan={3} className="" />
                            <td className="py-3" colSpan={2}>
                                <table className="w-full">
                                    <tr>
                                        <td className="py-2 ">Sous Total</td>
                                        <td className="py-2 font-semibold">{ formatNumber(order.items.map( (item : any) => (item?.price || 0) * (item?.qte || 0)).reduce( (prev : any, curr : any) => prev + curr)) }</td>
                                    </tr>
                                    <tr className="border-y-2 ">
                                        <td className="py-2 ">TPS/TVQ</td>
                                        <td className="py-2 font-semibold">{ formatNumber(order.items.map( (item : any) => item?.taxeEnv).reduce( (prev : any, curr : any) => prev + curr)) }</td>
                                    </tr>
                                    <tr>
                                        <td className="py-2 ">Total</td>
                                        <td className="py-2 font-semibold">{formatNumber(order.totalAmount)}</td>
                                    </tr>
                                </table>
                                
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
};
