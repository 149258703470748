import { useCallback, useContext } from "react";
import useApi from "./api/use-api";
import UpdatePasswordDto from '../models/user/update-password';
import { User, LoginResponse } from "../models";
import { AuthContext } from "../contexts/context/auth";
import SignupRequestDto from '../models/singup.dto';

export default function useAuth(){

    const {user, accessToken, setAuthSession, sessionHasExpired, setSessionExpired} = useContext(AuthContext)

    const {api} = useApi('')

    const signup = useCallback( async (signupData : SignupRequestDto ) => {
        return await api.post<User>('signup', signupData).then( ( {data} ) => data)
    }, [api])

    const askForNewActivationLink = useCallback( async (email : string) => {
        return await api.patch('new-activation-link', {}, { headers : { 'email' : email } })
    }, [api])
    
    const askForNewResetCode = useCallback( async (email : string) => {
        return await api.patch('new-reset-password-code', {}, { headers : { 'email' : email } })
    }, [api])

    const signin = useCallback( (loginRequest : {username : string, password : string}) => {
        return api.post<LoginResponse>('login', loginRequest ).then( ({data}) => setAuthSession( data ))
    }, [api, setAuthSession ])

    const logout = useCallback( async (sessionHasExpired? : boolean) => {
        setAuthSession(undefined)
        setSessionExpired(sessionHasExpired)
    }, [setAuthSession, setSessionExpired])

    const resetPasswordRequest = useCallback( (email : string) => {
        return api.patch('reset-password-request', {}, { headers : { email } })
    }, [api])

    const updatePasswordRequest = useCallback( async (updatePasswordDto : UpdatePasswordDto) =>{
        return await api.patch('update-password', updatePasswordDto)
    }, [api])

    const refreshToken = useCallback( async () => {
        await api.head<string>('refresh-session', {
            headers : { Authorization : `Bearer ${accessToken}`}
        })
        .then( ({data}) => setAuthSession( prev => ({...prev, token : data}) ) )
    }, [accessToken, setAuthSession, api])

    const getUserFullname = useCallback( () => user ? `${user.firstname} ${user.lastname}` : '', [user])
    
    return {
        user, 
        getUserFullname,
        accessToken, 
        signup,
        signin, 
        askForNewActivationLink, 
        askForNewResetCode, 
        logout, 
        resetPasswordRequest, 
        updatePasswordRequest, 
        refreshToken,
        sessionHasExpired
    }
}