import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import InputPatterns from "../utils/constants/input-patterns"
import { formatDate, getCurrentDate } from "../utils/helpers/date-time"
import { isMatchingPattern } from "../utils/helpers/regex"

export enum FieldInputType{
    TEXT = 'text',
    PASSWORD = 'password',
    SELECT_BOX = 'selectbox',
    NUMBER = 'number',
    PHONE = 'tel',
    FILE = 'file'
}

export enum FieldValidity{
    VALID = 'valid',
    EMPTY = 'empty',
    INVALID = 'invalid'
}

export type Field  = {
    type? : FieldInputType
    label? : string
    id? : string
    initialValue? : any
    placeholder? : string
    icon? : string
    required? : boolean
    readonly? : boolean
    pattern? : InputPatterns | string
    onChange? : (field : {name : string, value : any}) => void

    onEmptyErrorMessage? : string
    onInvalidErrorMessage? : string
    renderFieldComponent? : FunctionComponent<Field & {name : string, validity? : FieldValidity, hasError? : boolean}>

    extraData? : any
}

type props = {
    fields : {[x : string] : Field}
    renderFieldComponent? : FunctionComponent<Field & {name : string, validity? : FieldValidity, hasError? : boolean}>
    onChange? : (field : {name : string, value : any}) => void
    onSubmit? : ( fields : {[x : string] : any}) => void
    className? : string
    SubmitButton? : any
    children? : any
}

export function InputFieldTemplate1( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return (
        <div className='my-4'>
            <div className={`group overflow-hidden rounded-sm flex flex-1 h-10 border ${props.hasError ? 'border-red-500': (props.validity === FieldValidity.VALID) ? 'border-blue-200' : ''}`}>
                <label 
                    htmlFor={props.id}
                    className={`${props.icon} ${props.hasError ? 'bg-red-500 text-white' : (props.validity === FieldValidity.VALID) ? 'bg-blue-100' : 'group-focus-within:bg-primary bg-slate-100 group-focus-within:text-white'}   flex justify-center items-center w-10 h-full`} 
                />
                <input
                    id={props.id}
                    type={props.type} 
                    name={props.name} 
                    onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})} 
                    placeholder={props.placeholder}
                    defaultValue={props.initialValue}
                    readOnly={props.readonly}
                    className='h-full w-full pl-2' 
                />
            </div>
            { props.hasError && (
                <p className='text-red-500 text-xs mt-1 '> { props.validity === FieldValidity.EMPTY ? props.onEmptyErrorMessage : props.onInvalidErrorMessage } </p>
            )}
        </div>
    )
}

export function InputFieldTemplate2Big( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return (
        <>
            <div className="w-full my-8">
                <div className="my-2">
                    <label className="text-gray-300 text-xl" htmlFor={props.id}>{props.label}</label>
                    { props.hasError && (
                        <p className='text-red-500 text-xs my-1'>
                            { (props.validity === FieldValidity.EMPTY) ? props.onEmptyErrorMessage : props.onInvalidErrorMessage }
                        </p>
                    )}
                </div>
                <input  
                    className='p-2 h-12 w-full border rounded-sm'
                    id={props.id}
                    type={props.type} 
                    name={props.name} 
                    onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})} 
                    placeholder={props.placeholder}
                    defaultValue={props.initialValue}
                    readOnly={props.readonly} 
                />
            </div>
        </>
    )
}

export function InputFieldTemplate2Small( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return (
        <>
            <div className={`${!props.extraData?.fullWidth ? "sm:w-[49%]" : "" } w-full mt-2 `}>
                <div className="">
                    <label className="text-gray-500 text-lg" htmlFor={props.id}>{props.label}</label>
                    { props.hasError && (
                        <p className='text-red-500 text-xs'>
                            { (props.validity === FieldValidity.EMPTY) ? props.onEmptyErrorMessage : props.onInvalidErrorMessage }
                        </p>
                    )}
                </div>
                <input  
                    className='w-full p-2 h-8  border rounded-sm'
                    id={props.id}
                    type={props.type} 
                    name={props.name} 
                    onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})} 
                    placeholder={props.placeholder}
                    defaultValue={props.initialValue}
                    readOnly={props.readonly} 
                />
            </div>
        </>
    )
}

export function InputFieldTemplatePeriod(props : Field & {name : string, validity? : FieldValidity, hasError? : boolean}){
    
    const [period, setPeriod] = useState<{start : string, end : string}>({start : props.initialValue?.start?.toString() || new Date("2022-08-04").toString(), end : props.initialValue?.end?.toString() ||  getCurrentDate().toString()})
    const handleOnChange = useCallback( (e : any) => {
        const {name, value} = e.target

        setPeriod( prev => ({...prev, [name] : value}))
    }, [])

    useEffect( () => {
        props.onChange && props.onChange({name : props.name, value : period})
    }, [period, props])

    return(
        <div className="flex-1">
            <label htmlFor={props.id} className="text-gray-500">{props.label}</label>
            <p className="flex items-center gap-2" id={props.id}>
                <input 
                    name="start"
                    type="date" 
                    className="p-2 h-8 w-1/2  border rounded-sm"
                    defaultValue={formatDate(new Date(period.start))}
                    onChange={handleOnChange}
                />
                à
                <input 
                    name="end"
                    type='date' 
                    className="p-2 h-8 w-1/2  border rounded-sm"
                    defaultValue={formatDate(new Date(period.end))}
                    onChange={handleOnChange}
                />
            </p>
        </div>
    )
}

export default function MyForm({ fields, onChange, onSubmit, className, renderFieldComponent: RenderFieldComponent = InputFieldTemplate1, children} : props){
    const [states, setStates] = useState( { fields  })
    const formDatas = useRef( (() => {
        let datas : {[x : string] : string} = {}
        Object.entries(fields).forEach( ([fieldName, field]) => {
            datas[fieldName] = field.initialValue || ''
        })

        return datas
    })() ) 

    const validateField = useCallback( (field : Field, currentValue : string) => {
        
        if(field.required){
            if(!currentValue) return FieldValidity.EMPTY
        }

        if( field.pattern && !isMatchingPattern(currentValue, field.pattern) ){
            return FieldValidity.INVALID
        }

        return FieldValidity.VALID
    }, [])
    
    const handleSubmit = useCallback( (e : any) => {
        e.preventDefault()

        let anyError = false
        const newDatas : any = {}
        Object.entries(states.fields).forEach( ([fieldName, field]) => {
            const validity = validateField(field, formDatas.current[fieldName])
            newDatas[fieldName] = {...fields[fieldName], initialValue : formDatas.current[fieldName], validity, hasError : validity !== FieldValidity.VALID }
            anyError ||= validity !== FieldValidity.VALID
        })

        setStates( prev => ({...prev, fields : newDatas}))

        !anyError && onSubmit && onSubmit(formDatas.current)

    }, [fields, onSubmit, states.fields, validateField])
    
    const handleChange = useCallback( (field : {name : string, value : any}) => {
        formDatas.current = {...formDatas.current, [field.name] : field.value}
        onChange && onChange(field)
    }, [onChange])

    return (
        <form 
            onSubmit={handleSubmit}
            className={className}
            
            onKeyDown={ e => (e.key === 'Enter') && handleSubmit(e) }
        >
            { 
                Object.entries(states.fields)
                .map( ([key, field]) => (
                    field.renderFieldComponent ? (
                        <field.renderFieldComponent {...field} key={key} name={key} onChange={handleChange} />
                        // field.renderFieldComponent({...field, name : key, onChange : handleChange})
                    ) : (
                        <RenderFieldComponent {...field} key={key} name={key} onChange={handleChange} />
                        // <RenderFieldComponent({...field, name : key, onChange : handleChange}) />
                    )
                )) 
            }

            {children}
        </form>
    )
}