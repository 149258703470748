import { Link } from "react-router-dom"

type props = {
    children? : any
    className? : string
    style? : any
    onClick? : (e? : any) => void
    link? : string
    disabled? : boolean
    preventDefault? : boolean
}

export default function Button({children, link, disabled, className = '', style, onClick, preventDefault} : props){
    return link ? (
        <Link to={link} onClick={ e => onClick && onClick(e)} className={`px-6 py-2 bg-primary text-white hover:bg-primary-deep rounded-sm ${className}`} style={style}>
            {children}
        </Link>
    ) : (
        <button 
            className={`${className} px-6 py-2 bg-primary text-white hover:bg-primary-deep rounded-sm  disabled:pointer-events-none disabled:bg-opacity-80`} style={style}
            onClick={ e => {
                preventDefault && e.preventDefault()
                onClick && onClick(e)
            }}
            disabled={disabled}
        >
            {children}
        </button>
    )
}