import { useCallback, useEffect, useMemo, useRef, useState } from "react"

type Option = {
    value? : string,
    label : string
}
type props = {
    name? : string
    placeholder : string
    options? : Option[]
    selected? : string
    onChange? : (option : Option) => void
    maxItems? : number
    search? : boolean
    onSearch? : (searchText : string) => void
    sort? : boolean
    className? : string
    id? : string
    onEndListReached? : () => void
    isLoadingData? : boolean
}

export default function CustomSelectBox({className = '', id = Date.now().toString(), placeholder, name = '' , options = [], selected,maxItems = 5,isLoadingData, onChange, search, onSearch, onEndListReached, sort} : props){
    const [showDropdown, setShowDropdown] = useState(false)
    const [currentOption, setCurrentOption] = useState(options.find( option => option.value === selected))
    const label = useMemo( () => currentOption?.label || placeholder, [currentOption, placeholder])
    const componentRef = useRef<HTMLDivElement | null>(null)
    const optionsListRef = useRef<HTMLDivElement | null>(null)
    

    const searchProcessRef = useRef<number>()
    const onSearchChange = useCallback( (searchText : string) => {
        if(searchProcessRef.current){
            clearTimeout(searchProcessRef.current)
            searchProcessRef.current = undefined
        }

        searchProcessRef.current = setTimeout( () => onSearch && onSearch(searchText), 1500, searchText )
    }, [onSearch])

    const isCurrent = useCallback( (optionValue : Option) => {
        if(!currentOption){
            return false
        }else if(currentOption.label === optionValue.label){
            return true
        }else{
            return false
        }
        
    }, [currentOption])

    const RenderOption = useCallback( ({option} : {option : Option}) => (
        <button 
            className={`w-full flex items-center py-2 px-1 text-sm cursor-pointer hover:bg-gray-100 ${isCurrent(option) ? 'bg-gray-100 font-semibold' : ''}`} 
            onClick={ () => {
                setShowDropdown(false)
                setCurrentOption(option)
            }}
        >
            {isCurrent(option) && <i className="fas fa-check mx-1 " />}
            <span className=''>{option.label}</span>
        </button>
    ), [isCurrent])

    useEffect( () => {
        setCurrentOption( options.find( option => option.value === selected) )
    }, [selected, options])

    useEffect( () => {
        
        currentOption && onChange && onChange(currentOption)
    }, [currentOption, onChange])

    useEffect( () => {
        optionsListRef.current!.onscroll = () => {
            if (optionsListRef.current!.offsetHeight + optionsListRef.current!.scrollTop >= optionsListRef.current!.scrollHeight) {
                !isLoadingData && onEndListReached && onEndListReached()
            }
        }
    }, [isLoadingData, onEndListReached])

    return(
        <div
            id={id}
            ref={componentRef}
            
            onBlur={ e =>  {
                setTimeout( () => {
                    const activElInDoc = document.activeElement
                    let hasFocusIn = false
                    componentRef.current?.querySelectorAll('button')?.forEach( btn => {
                        if(btn === activElInDoc){
                            hasFocusIn = true
                            return
                        }
                    })
                    !hasFocusIn && componentRef.current?.querySelectorAll('input')?.forEach( input => {
                        if(input === activElInDoc){
                            hasFocusIn = true
                            return
                        }
                    })

                    if(!hasFocusIn){
                        setShowDropdown(false)
                    }
                }, 30)
            } }
            className={`relative ${showDropdown ? '' : 'overflow-hidden'}`} >
            <button onClick={ () =>  setShowDropdown(prev => !prev)} className={`cursor-pointer flex justify-between items-center h-8 border ${className}`} >
                <span className="truncate whitespace-nowrap cursor-pointer p-1 flex-1 text-left" >
                    {label}
                </span>
                <span className="flex justify-center items-center w-6 border-l h-full cursor-pointer  text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                    <i className={`fas fa-angle-right ${showDropdown ? '-rotate-90' : 'rotate-90'}`} />
                </span>
            </button>
            <div className={` absolute z-20 w-full bg-gray-50 rounded shadow`} >
                { search && (
                        <div className="flex items-center gap-1 bg-gray-600 text-sm text-white border">
                            <label className="fas fa-search mx-1" />
                            <input onClick={ e => e.stopPropagation()} placeholder="taper pour filtrer" type="text" className="flex w-full h-8 bg-transparent placeholder:text-white" onChange={e => onSearchChange(e.target.value)} />
                        </div>
                    )
                }
                <div ref={optionsListRef} className="divide-y max-h-[10.5rem] overflow-y-auto">
                    {
                        options.map( (option, index) => <RenderOption key={index} option={option} />)
                    }
                    {isLoadingData && <div className="w-10 h-10 mx-auto bg-slate-400 animate-pulse rounded-full" />}

                </div>
            </div>

            <input type="hidden" name={name}  />
        </div>
    )
}